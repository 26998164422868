<template>
	<div class="font ajust-margin" style="margin-top: 4%; margin-bottom: 4%">
		<b-navbar
			toggleable="sm"
			type="light"
			variant="white"
			style="margin-bottom: -1%; margin-left: -1%"
		>
			<b-navbar-brand style="">
				<p class="page_init mb-0">PRÉ-CONTRATO</p>
				<!-- <p
					v-if="entrega.product.length > 0"
					class="title"
					style="font-size: 20px"
				>
					Editar Endereço de Pré-Contrato
				</p> -->
				<p class="title" style="font-size: 20px">
					Novo Endereço de Pré-Contrato
				</p>
			</b-navbar-brand>

			<!-- Right aligned nav items -->
		</b-navbar>

		<form
			style="margin-top: -15px"
			id="form-entrega"
			@submit.prevent="createEntrega()"
		>
			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Equipamento</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							color: #88878d;
						"
						type="text"
						class="form-control"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						v-model="product.product_obj.nome"
						disabled
					/>
				</div>

				<div
					class="col-sm-12 col-md-3"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Responsável</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.contato"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>

				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Departamento/Setor</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						v-model="entrega.department"
						required
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Tel. Responsável</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-mask="'(##) #####-####'"
						v-model="entrega.fone"
						pattern="^[0-9-(-) ]*$"
						oninvalid="this.setCustomValidity('Digite apenas números')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				<div
					class="col-sm-12 col-md-3"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Dia e Hora</label>
					<date-picker
						style="display: unset"
						input-class="form-control crm-input date-picker"
						format="DD/MM/YYYY HH:mm"
						type="datetime"
						required
						id="previsaoFim"
						v-model="entrega.date_and_time"
						:input-attr="{ required: true }"
					></date-picker>
				</div>
			</div>
			<div class="row" style="margin: 1%; text-align: left">
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">CEP da Entrega</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-mask="'#####-###'"
						v-model="entrega.cep"
						@keyup="onKeyup"
						@keydown="onKeydown($event)"
						pattern="^[0-9--]{9}"
						oninvalid="this.setCustomValidity('Digite 8 números')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>

				<div
					class="col-sm-12 col-md-6"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Endereço</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.logradouro"
						required
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras ou números')"
						oninput="setCustomValidity('')"
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Numero</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.numero"
						pattern="^[0-9]+"
						oninvalid="this.setCustomValidity('Digite apenas números')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Complemento</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.comp"
						pattern="^[a-zA-Z0-9-/\W|_/ ]*$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				</div>
				<div class="row" style="margin: 1%; text-align: left">
					<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Bairro</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.bairro"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Cidade</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						v-model="entrega.cidade"
						pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s ]+$"
						oninvalid="this.setCustomValidity('Digite apenas letras')"
						oninput="setCustomValidity('')"
						required
					/>
				</div>
				<div
					class="col-sm-12 col-md-2"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="">Estado</label>
					<select
						name="estados-brasil"
						v-model="entrega.estado"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						class="form-control"
						id="inputEstado"
						required
					>
						<option
							disabled
							selected
							value
							style="display: none"
						></option>
						<option value="AC">Acre</option>
						<option value="AL">Alagoas</option>
						<option value="AP">Amapá</option>
						<option value="AM">Amazonas</option>
						<option value="BA">Bahia</option>
						<option value="CE">Ceará</option>
						<option value="DF">Distrito Federal</option>
						<option value="ES">Espírito Santo</option>
						<option value="GO">Goiás</option>
						<option value="MA">Maranhão</option>
						<option value="MT">Mato Grosso</option>
						<option value="MS">Mato Grosso do Sul</option>
						<option value="MG">Minas Gerais</option>
						<option value="PA">Pará</option>
						<option value="PB">Paraíba</option>
						<option value="PR">Paraná</option>
						<option value="PE">Pernambuco</option>
						<option value="PI">Piauí</option>
						<option value="RJ">Rio de Janeiro</option>
						<option value="RN">Rio Grande do Norte</option>
						<option value="RS">Rio Grande do Sul</option>
						<option value="RO">Rondônia</option>
						<option value="RR">Roraima</option>
						<option value="SC">Santa Catarina</option>
						<option value="SP">São Paulo</option>
						<option value="SE">Sergipe</option>
						<option value="TO">Tocantins</option>
					</select>
				</div>

				<div
					class="col-sm-12 col-md-6"
					style="font-size: 12px; color: #878ea4"
				>
					<label for="modelo">Nome do site:</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						class="form-control"
						type="text"
						v-model="entrega.nome"
						required
					/>
				</div>
			</div>

			<button
				type="submit"
				class="btn btn-secondary py-2 px-4"
				style="
					font-size: 11px;
					margin-top: 20px;
					background: #878787 0% 0% no-repeat padding-box;
					border-radius: 100px;
					opacity: 1;
				"
				@click="cancelled()"
			>
				CANCELAR
			</button>
			<button
				:disabled="!formIsValid"
				type="submit"
				class="btn btn-primary py-2 px-4"
				style="
					font-size: 11px;
					margin-top: 20px;
					margin-left: 10px;
					background: #0bb4d4 0% 0% no-repeat padding-box;
					border-radius: 100px;
					opacity: 1;
				"
			>
				SALVAR
			</button>
		</form>
	</div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
.date-picker
  background-color:#f5f4fc !important
</style>

<script>
// import { required, numeric, alphaNum, alpha } from "vuelidate/lib/validators";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import ProposalService from "../../services/ProposalsService/ProposalsService";
import SiteService from "../../services/SiteService/SiteService";

import Swal from "sweetalert2";
import ProductProposalService from "../../services/ProductsProposalsService/ProductProposalsService";

export default {
	data() {
		return {
			siteService: new SiteService(),
			product_service: new ProductProposalService(),
			enderecos: {},
			entrega: {
				contato: "",
				fone: "",
				date_and_time: "",
				cep: "",
				logradouro: "",
				cidade: "",
				estado: "",
				codcli: undefined,
				foneaux: "1",
				contrato: this.$route.params.id,
				nome: "",
				product: [],
				qtd: "",
			},
			product: [],
		};
	},
	components: {
		DatePicker,
	},
	mounted() {
		this.enderecos = this.siteService.list();
		let proposal_service = new ProposalService();
		proposal_service
			.retrieve(this.$route.params.id)
			.then(({ data: proposal }) => {
				this.entrega.codcli = proposal.client;
			});
		let id = this.$route.params.product_id;
		this.product_service.retrieve(id).then(({ data }) => {
			this.product = data;
			// console.log(this.product);
		});
		this.updated = !this.updated;

		if(this.$route.params.id_endereco){
			this.siteService.retrieve(this.$route.params.id_endereco).then( 
				value => {
					this.copy_endereco(value.data)
				}
			)
		}
	},

	watch: {
		"entrega.cep": {
			handler() {
				var cep = this.entrega.cep.replace(/\D/g, "");
				if (!/^[0-9]{8}$/.test(cep)) return;

				axios
					.get("https://viacep.com.br/ws/" + cep + "/json/")
					.then((response) => response.data)
					.then((obj) => {
						this.entrega.logradouro = obj.logradouro;
						this.entrega.estado = obj.uf;
						this.entrega.cidade = obj.localidade;
					});
			},
		},
	},

	methods: {

		copy_endereco(endereco){
			this.entrega.contato = endereco.contato,
			this.entrega.fone = endereco.fone,
			this.entrega.date_and_time = endereco.date_and_time,
			this.entrega.cep = endereco.cep,
			this.entrega.logradouro = endereco.logradouro,
			this.entrega.cidade = endereco.cidade,
			this.entrega.estado = endereco.estado,
			this.entrega.nome = endereco.nome
			this.entrega.department = endereco.department
			this.entrega.numero = endereco.numero
			this.entrega.comp = endereco.comp
		},

		onKeydown: function (e) {
			if (
				// permite somente numeros
				(e.keyCode >= 48 && e.keyCode <= 57) ||
				(e.keyCode >= 96 && e.keyCode <= 105) ||
				// permite teclas lado direito, esquerdo, delete, backspace, tab e enter
				/^(8|9|13|46|37|39|17)$/.test(e.keyCode) ||
				// permite ctrl+ c,v,x,a,z
				(/^(67|86|88|65|90)$/.test(e.keyCode) && e.ctrlKey)
			)
				return;
			e.preventDefault();
			e.stopPropagation();
		},
		onKeyup: function () {
			var cep = this.entrega.cep.replace(/\D/g, "");
			if (!/^[0-9]{8}$/.test(cep)) return;

			axios
				.get("https://viacep.com.br/ws/" + cep + "/json/")
				.then((response) => response.data)
				.then((obj) => {
					this.entrega.logradouro = obj.logradouro;
					this.entrega.estado = obj.uf;
					this.entrega.cidade = obj.localidade;
				});
		},
		cancelled() {
			this.$router.push({
				name: "editPrecontrato",
				params: { id: this.$route.params.id, step: 3 },
			});
		},

		createEntrega() {
			this.submmited = true;
			let entrega = Object.assign({}, this.entrega);
			var cep = entrega.cep.replace(/\D/g, "");
			var fone = entrega.fone.replace(/\D/g, "");

			entrega.cep = cep;
			entrega.fone = fone;

			let promise = new Promise((resolve) => {
				resolve();
			});

			let productProposalId = this.$route.params.product_id;

			promise = Swal.fire({
				text: "Endereço criado com sucesso.",
				icon: "success",
				showConfirmButton: true,
				confirmButtonText: "Ok",
				confirmButtonColor: "#0bb4d4",
			})
				.then(
					this.siteService.create(entrega).then((res) =>
						this.siteService.add_product_proposal(
							// visualizar no back depois se realmente funciona igual a maneira como está no editEndereco
							res.data,
							productProposalId
						)
					)
				)
				.catch(() => {
					console.log("catch");
					Swal.fire({
						title: "Erro!",
						text: "Não foi possivel adicionar o endereço, verifique o formulário",
						icon: "error",
						showCancelButton: false,
						confirmButtonColor: "#0bb4d4",
						cancelButtonColor: "#A9A9A9",
						confirmButtonText: "OK",
						cancelButtonText: "Cancelar",
					});
				});

			promise.then(() => {
				this.$emit("added-adress", this.entrega);
				this.$router.push({
					name: "editPrecontrato",
					params: { id: this.$route.params.id, step: 4 },
				});
			});
		},
	},
	computed: {
		formIsValid() {
			return (
				this.entrega.contato &&
				this.entrega.fone &&
				this.entrega.date_and_time &&
				this.entrega.cep &&
				this.entrega.logradouro &&
				this.entrega.cidade &&
				this.entrega.estado &&
				this.entrega.codcli &&
				this.entrega.foneaux &&
				this.entrega.nome
			);
		}
	}
};
</script>
